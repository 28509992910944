import InteractiveGrid from "../../../../content/responsive-layouts/interactiveGrid.tsx";
import ResponsiveGridGenerator from "../../../../content/responsive-layouts/responsiveGridGenerator.tsx";
import FixedSizeColumns from "../../../../content/responsive-layouts/fixedSizeColums.tsx";
import CodeBlock from "../../../../src/components/codeBlock.tsx";
import Responsive1FrColumns from "../../../../content/responsive-layouts/responsive1frColumns.tsx";
import ResponsiveAutoFillFit from "../../../../content/responsive-layouts/responsiveAutoFillFit.tsx";
import MinFnGapSize from "../../../../content/responsive-layouts/minFnGap.tsx";
import AllTogether from "../../../../content/responsive-layouts/allTogether.tsx";
import * as React from 'react';
export default {
  InteractiveGrid,
  ResponsiveGridGenerator,
  FixedSizeColumns,
  CodeBlock,
  Responsive1FrColumns,
  ResponsiveAutoFillFit,
  MinFnGapSize,
  AllTogether,
  React
};